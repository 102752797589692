import create from 'zustand'

export const usePersonalInfo = create(set => ({
  field_first_name: [
    {
      value: ''
    }
  ],
  field_last_name: [
    {
      value: ''
    }
  ],
  field_gender: [
    {
      value: ''
    }
  ],
  field_date_of_birth: [
    {
      value: ''
    }
  ],
  field_role: [
    {
      value: ''
    }
  ]
}))
