import React from 'react'
import styled from 'styled-components'
import { colors, mq } from '../../styles/theme'
import OrbImage from '../OrbImage';

const ResourceCardItem = ({ className, cardLink, cardSubheading, cardHeading, resourceType, resourceGoal, orbType  }) => {
  return (
    <ResourceCardLink className={className} href={cardLink}>
      <ResourceCard>
        <OrbImageWrapper>
          <OrbImage type={orbType}/>
        </OrbImageWrapper>
        <RelatedResourcesUpper>
          <ResourceCardSource>{cardSubheading}</ResourceCardSource>
          <ResourceCardHeading>{cardHeading}</ResourceCardHeading>
        </RelatedResourcesUpper>
        <RelatedResourcesBottom>
          <ResourceCardType>{resourceType}</ResourceCardType>
        </RelatedResourcesBottom>
      </ResourceCard>
    </ResourceCardLink>
  )
}

export default ResourceCardItem

const RelatedResourcesUpper = styled.div``

const RelatedResourcesBottom = styled.div`
  display: flex;
  max-inline-size: 250px;
  flex-direction: column;
`

const OrbImageWrapper= styled.div`
  width: 180px;
  bottom: 0;
  position: absolute;
  right: 0;
  transform: translate(35%,45%);
  z-index: 1;
  transition: all 0.4s ease-in-out;
`

const ResourceCardHeading = styled.h3`
  color: ${colors.black};
  font-family: "BuckeyeSans";
  font-weight: 600;
  font-size: 16px;

  ${mq.tablet} {
    font-size: 23px;
  }
`

const ResourceCard = styled.div`
  background-color: ${colors.white};
  background-size: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-block-size: 183px;
  padding: 40px 30px;
  overflow: hidden;
  position: relative;

  ${mq.tablet} {
    background-size: 100px;
    min-block-size: 271px;
  }

  &:hover {
    ${OrbImageWrapper}{
      transform: translate(35%,45%) scale(1.2) rotateZ(15deg);
    }

    ${ResourceCardHeading}{
      color: ${colors.primary};
    }
  }
`

const ResourceCardLink = styled.a`
  text-decoration: none;
`

const ResourceCardSource = styled.h6`
  color: ${colors.darkGray};
  font-family: "BuckeyeSans";
  font-size: 13px;
  margin-block-end: 5px;
`

const ResourceCardType = styled.span`
  color: ${colors.darkGray};
  font-family: "BuckeyeSans";
  font-weight: 700;
  font-size: 12px;
`

const ResourceCardGoalLabel = styled.span`
  color: ${colors.darkGray};
  font-family: "BuckeyeSans";
  font-size: 12px;
`
