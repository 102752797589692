import 'swiper/css/bundle';
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/pagination';

import React, { useRef, useCallback, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Grid, Pagination } from 'swiper';
import styled from 'styled-components';
import ResourceCardItem from '../../components/ResourceCard';
import { colors, mq } from '../../styles/theme';
import chevronLeftIcon from '../../assets/slider-arrow-left.svg'
import chevronRightIcon from '../../assets/slider-arrow-right.svg'
import { getFavoriteResources } from '../../services/dashboard';

const DashboardFavoritesRow = () => {
  const sliderRef = useRef(null);
  const [favoriteResources, setFavoriteResources] = useState(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = () => {
    if (favoriteResources?.pager.page < favoriteResources?.pager.total_pages) {
      getFavoriteResources(favoriteResources.pager.page + 1).then(data => {
        setFavoriteResources(currentData => {
          return {
            pager: data.pager,
            resources: [...currentData.resources, ...data.resources]
          }
        })
      }).catch(e => {
        console.error('Error trying to get dashboard favorite resources', e.message)
      })
    } else {
      if (!sliderRef.current) return;
      sliderRef.current.swiper.slideNext();
    }
  };

  const sliderConfig = {
    340: {
      slidesPerView: 1,
      spaceBetween: 20,
      grid: {
        rows: 2
      }
    },
    768: {
      slidesPerView: 2,
      spaceBetween: 20,
      grid: {
        rows: 2
      }
    },
    1024: {
      slidesPerView: 3,
      spaceBetween: 30,
      grid: {
        rows: 2
      }
    }
  }

  const getFirstOf = (list, type) => {
    let response

    response = list.find(element => {
      return element.type === type
    })

    return response
  }

  const processData = (data) => {
    data.resources.forEach(resource => {
      let firstGoal = getFirstOf(resource.taxonomies || [], 'resource_goals_topics')
      let firstType = getFirstOf(resource.taxonomies || [], 'resource_content_types')

      resource['first_goal'] = firstGoal
      resource['first_type'] = firstType
    })
  }

  useEffect(() => {
    getFavoriteResources(1).then(data => {
      processData(data)
      setFavoriteResources(data)
    }).catch(e => {
      console.error('Error trying to get dashboard favorite resources', e.message)
    })
  }, []);

  useEffect(() => {
    if (favoriteResources && favoriteResources.pager.page > 1) {
      if (!sliderRef.current) return;
      sliderRef.current.swiper.slideNext();
    }
  }, [favoriteResources]);


  return (
    <>
      {
        favoriteResources?.resources?.length > 0 &&
          <DashboardFavorites>
            <DashboardTitleWrapper>
              <FavoriteHeading>Your Favorite Resources</FavoriteHeading>
              <SwiperArrows>
                <ButtonLeft onClick={handlePrev}><ButtonIcon src={chevronLeftIcon}/></ButtonLeft>
                <ButtonRight onClick={handleNext}><ButtonIcon src={chevronRightIcon}/></ButtonRight>
              </SwiperArrows>
            </DashboardTitleWrapper>
            <Swiper
              ref={sliderRef}
              modules={[Grid, Pagination]}
              breakpoints={sliderConfig}
            >
              {favoriteResources?.resources?.map((el, i) => (
                <SwiperSlide key={i}>
                  <DashboardItem
                    cardLink={`/resources${el.path}`}
                    cardSubheading={el.source}
                    cardHeading={el.title}
                    resourceType={el.first_type ? el.first_type.name : ''}
                    resourceGoal={el.first_goal ? el.first_goal.name : ''}
                    orbType={el.first_goal ? el.first_goal.theme || 'health' : 'health'}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            <SwiperArrowsMobile>
              <ButtonLeft onClick={handlePrev}><ButtonIcon src={chevronLeftIcon}/></ButtonLeft>
              <ButtonRight onClick={handleNext}><ButtonIcon src={chevronRightIcon}/></ButtonRight>
            </SwiperArrowsMobile>
          </DashboardFavorites>
      }
    </>
  );
}

export default DashboardFavoritesRow

const DashboardFavorites = styled.section`
  margin-block-end: 40px;
  width: 100%;

  ${mq.tablet} {
    margin-block-end: 100px;
  }

  .swiper {
    height: 420px;
    margin-block-end: 30px;
    margin-left: auto;
    margin-right: auto;

    ${mq.tablet} {
      height: 570px;
      margin-block-end: initial;
    }
  }

  .swiper-wrapper {
    justify-content: space-between;
  }

  /* Override Swiper Styles */

  .swiper-slide {
    align-items: center;
    display: flex;
    height: calc((100% - 50px) / 2) !important;
    justify-content: center;

    ${mq.tablet} {
      height: calc((100% - 30px) / 2) !important;
    }
  }
`

const DashboardItem = styled(ResourceCardItem)`
  width: 100%;
`

const FavoriteHeading = styled.h2`
  font-family: "BuckeyeSans";
  font-weight: 700;
  font-size: 30px;
  line-height: 1;
  max-inline-size: 275px;

  ${mq.desktop} {
    font-size: 40px;
    max-inline-size: none;
  }
`

const SwiperArrows = styled.div`
  column-gap: 20px;
  display: none;

  ${mq.tablet} {
    display: flex;
  }
`

const SwiperArrowsMobile = styled.div`
  column-gap: 20px;
  display: flex;
  justify-content: flex-end;

  ${mq.tablet} {
    display: none;
  }
`

const DashboardTitleWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-block-end: 40px;
`

const ButtonLeft = styled.button`
  background: ${colors.white};
  border: none;
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  padding: 13px;
`

const ButtonRight = styled.button`
  background: ${colors.white};
  border: none;
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  padding: 13px;
`

const ButtonIcon = styled.img``
