import React, { useEffect, useState } from 'react'
import Layout from '../components/Layout/layout'
import QuoteRow from '../components/QuoteRow';
import WideContainer from '../components/WideContainer/widecontainer';
import ContactGrid from '../components/ContactGrid';
import FeaturedContentWithLinks from '../components/FeaturedContentRowWLinks';
import DashboardFavoritesRow from '../components/DashboardFavoritesRow';
import DashboardMasthead from '../components/DashboardMasthead';
import { getStatus } from '../services/questionnaire';
import { getImage } from 'gatsby-plugin-image';

const DashboardLandingPage = (props) => {

  let data = props.pageContext.data
  const [questionnaireStatus, setQuestionnaireStatus] = useState(null);
  const [quoteRowData, setQuoteRowData] = useState(null);
  const [contactGridData, setContactGridData] = useState(null);

  useEffect(() => {
    getStatus().then(status => {
      setQuestionnaireStatus(status)
    }).catch(e => {
      console.error('Error trying to get questionnaire status', e.message)
    })

    const quoteRowDataAux = data?.nodeDashboardLandingPage.relationships?.field_quote_row
    const gatsbyImage = getImage(quoteRowDataAux?.relationships?.field_prgph_image?.relationships?.field_media_image.localFile)
    setQuoteRowData({...quoteRowDataAux, ...gatsbyImage})

    const contactGridDataAux = data?.nodeDashboardLandingPage.relationships?.field_contact_grid

    const contactGridList = []
    if (contactGridDataAux?.relationships?.field_contact_items?.length) {
      contactGridDataAux.relationships.field_contact_items.forEach((item) => {
        contactGridList.push({
          heading: item.field_prgph_heading_plain_text,
          sub_heading: item.field_prgph_sub_headi,
          phone: item.field_phone,
          email: item.field_email,
          email_label: item.field_label_email_1,
          image: item.relationships.field_contact_image.relationships.field_media_image.localFile
        })
      })
    }

    setContactGridData({
      heading: contactGridDataAux?.field_prgph_heading_plain_text,
      list: contactGridList
    })

  }, []);


  return (
    <Layout>
      <DashboardMasthead {...data?.nodeDashboardLandingPage} questionnaireStatus={questionnaireStatus}/>
      <WideContainer>
        {quoteRowData &&
          <QuoteRow
            image={quoteRowData?.images?.fallback?.src}
            copy={quoteRowData?.field_prgph_copy || ''}
            name={quoteRowData?.field_name || ''}
            position={quoteRowData?.field_position || ''}
          />
        }
        
        <DashboardFavoritesRow/>
      </WideContainer>
    </Layout>
  )
}

export default DashboardLandingPage

export { Head } from '../components/_GatsbyPageHead'
