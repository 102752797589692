import React, { useState, useEffect} from 'react'
import { navigate } from 'gatsby';
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormController from './FormController'
import ServerMessage from '../ServerMessage'
import Button from '../Button'
import Link from '../Link'
import styled from 'styled-components'
import { colors, mq } from '../../styles/theme'
import { handleLogin, requestSSOHandler, isAuthSSO } from '../../services/auth'
import Rtf from '../Rtf/Article'

function FormikWrapper({ signInLink, signInText }) {
  const [triggeredError, setTriggeredError] = useState(false)

  const initialValues = {
    email: '',
    password: ''
  }

  const choices = [
    { key: 'Remember Me', value: 'Remember Me' }
  ]

  const validationSchema = Yup.object({
    email: Yup.string().required('Required').email('Please enter a valid email address.'),
    password: Yup.string().required('Please enter a password.')
  })

  // SSO WORKFLOW

  // 1. User clicks the SSO button
  // 2. User gets redirected to IDP, should redirect back to login page
  // 3. User gets redirected back with cookies from IDP
  // 4. Then send request with cookies to Drupal endpoint
  // 5. Endpoint returns JWT which is valid or not (same as usual login process)

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const isSSO = params.get('sso');
    const csrfToken = params.get('token');
    const timestamp = params.get('timestamp');

    if (isSSO){
      requestSSOHandler(csrfToken, timestamp).then(el => {
        if (el) {
          const from = getRedirectPath()
          navigate(`/${from !== null? from : ''}`);
        }
      })
    }
  }, [])

  const onSubmit = values => {
    validationSchema
      .isValid(values)
      .then(async valid => {
        const res = await handleLogin(values.email, values.password)
        // eslint-disable-next-line no-undefined
        if (!res){
          setTriggeredError(true)
        } else {
          const queryParams = new URLSearchParams(window.location.search)
          const from = queryParams.get("from")

          setTriggeredError(false)
          localStorage.setItem('username', JSON.stringify(values.email));
          navigate(`/${from ?? ''}`);
        }
      }).catch(e => {
        setTriggeredError(true)
      });
  }

  const getRedirectPath = () => {
    const queryParams = new URLSearchParams(window.location.search)
    const from = queryParams.get("from");
    return from !== null ? from : 'app/dashboard';
  }

  const isBrowser = typeof window !== "undefined"

  if(isBrowser) {
    const getHost = () => {
      const { protocol, host } = window.location
      return `${protocol}//${host}`
    }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {formik => (
        <Form method="post">
          {
            triggeredError &&  <ServerMessage messagetype='error' triggered={true} errorMessage='Incorrect username or password.'/>
          }
          <FormController
            control="input"
            type="email"
            label="Email"
            name="email"
            error={ formik.errors.email }
          />
          <FormController
            control="input"
            type="password"
            label="Password"
            name="password"
            onKeyUp={() => {
              formik.setErrors({ ...formik.errors, 'password': null })
              formik.setFieldTouched('password', false, false)
            }}
            error={ formik.errors.password }
          />
          <FormForgotContainer>
            <FormController
              control="checkbox"
              name="checkBoxChoice"
              options={choices}
            />
            <FormForgotPassword href='/forgotpassword' text='Forgot password?' type='primary'/>
          </FormForgotContainer>
          <FormButton isSubmit text="Sign in" />

          <SingleSignOnBtn href={`${process.env.DRUPAL_JSONAPI_URL}sso-login?ReturnTo=${getHost()}/${getRedirectPath()}`}>
            <SingleSignOnBtnText>SSO Sign in</SingleSignOnBtnText>
          </SingleSignOnBtn>

          <FormSigninLabel>
            <Rtf copy={signInText}/> <FormSigninLink type='primary' href='/signup' text={signInLink} />
          </FormSigninLabel>
        </Form>
      )}
    </Formik>
  )
  }
}
export default FormikWrapper

const FormSigninLabel = styled.div`
  display: flex;
  justify-content: center;
`

const FormSigninLink = styled(Link)`
  margin-inline-start: 5px;
`

const FormForgotContainer = styled.div`
  display: flex;
  justify-content: space-between;

  label{
    font-family: "BuckeyeSans";
    font-size: 13px;
    margin-block-end: 0;
  }
`

const FormForgotPassword = styled(Link)`
  font-size: 14px;
  justify-content: flex-end;

  ${mq.desktop} {
    font-size: 13px;
  }
`

const FormButton = styled(Button)`
  margin-block-end: 20px;
  width: 100%;
`

const SingleSignOnBtn = styled.a`
  background: transparent;
  border: 2px solid ${colors.primary};
  color: ${colors.primary};
  cursor: pointer;

  //Please change this to other than none to show the SSO btn (Set to none for testing - Go live)
  display: none;

  margin-block-end: 50px;
  padding: 13px 18px;
  text-align: center;
  text-decoration: none;
  position: relative;
  -webkit-backface-visibility: hidden;
  width: 100%;
  z-index: 1;

  &::after{
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to right, ${colors.gradientGreen}, ${colors.primary});
    transition: opacity 0.3s ease-out;
    z-index: 2;
    opacity: 0;
  }

  &:hover::after{
    opacity: 1;
  }

  &:hover {
    border: none;
    color: ${colors.white};
    padding: 15px 18px;
  }
`

const SingleSignOnBtnText = styled.span`
  position: relative;
  z-index: 3;
`
