import React from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormController from './FormController'
import Button from '../Button'
import styled from 'styled-components'
import { colors, mq } from '../../styles/theme'
import { handleChangeUserInfo } from '../../services/profile'
import { useAlert } from '../../contextHooks/alerts'
import { isAuthSSO } from '../../services/auth'

function FormikWrapper({ onClose, userContact }) {

  const formatPhone = (phone) => {
    const match = phone.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `${match[1]}-${match[2]}-${match[3]}`;
    }
    return phone
  }

  const initialValues = {
    phone: userContact.field_phone_number[0]?.value ? formatPhone(userContact.field_phone_number[0]?.value) : '',
    email: userContact.mail[0]?.value || ''
  }

  const validationSchema = Yup.object({
    phone: Yup.string().test('phone', 'Please enter a valid phone number.', function (value) {
      const cleaned = ('' + value).replace(/\D/g, '')
      const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return true;
      }
      return false;
    }),
    email: Yup.string().required('Email is a required field').email('Please enter a valid email address.')
  })

  const onSubmit = values => {

    if (values.phone) {
      values.phone = ('' + values.phone).replace(/\D/g, '')
    }

    validationSchema
      .isValid(values)
      .then(async valid => {
        const body = JSON.stringify({
          mail: [{
            value: values.email
          }],
          field_phone_number: [{
            value: values.phone
          }]
        })
        const res = await handleChangeUserInfo(body)
        if (res){
          useAlert.setState({
            text: "You've successfully updated your Contact info.",
            triggered: true,
            messageType: 'success'
          })
          onClose()
        }
      })
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {formik => (
        <Form>
          <FormFieldWrapper>
            <FormField>
              <FormController
                control="input"
                type="text"
                label="Phone Number"
                name="phone"
              />
            </FormField>
            {
              !isAuthSSO() &&
                <FormField>
                  <FormController
                    control="input"
                    type="email"
                    label="Email"
                    name="email"
                    error={ formik.errors.email }
                  />
                </FormField>
            }
          </FormFieldWrapper>
          <FormButtonContainer>
            <FormButton isSubmit text="Save Changes" />

            <FormSigninLabel>
              <LinkPrimitive type='button' onClick={() => onClose()}>
                <LinkText>Cancel</LinkText>
              </LinkPrimitive>
            </FormSigninLabel>
          </FormButtonContainer>
        </Form>
      )}
    </Formik>
  )
}
export default FormikWrapper

const FormSigninLabel = styled.p`
  display: flex;
  justify-content: center;
`

const FormFieldWrapper = styled.div`
  column-gap: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${mq.tablet} {
    flex-direction: row;
  }
`

const FormField = styled.div`
  flex: 1;
`

const FormButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  margin-block-start: 15px;
  margin-block-end: 50px;
  row-gap: 20px;

  ${mq.tablet} {
    column-gap: 40px;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    row-gap: 0;
  }
`

const FormButton = styled(Button)`
  margin-block-start: 0;
  width: 100%;

  ${mq.tablet} {
    width: auto;
  }
`

const LinkPrimitive = styled.button`
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  color: ${colors.primary};
  font-size: 14px;
  line-height: 18px;
  margin-inline-start: 5px;
  text-decoration: none;

  ${mq.desktop} {
    font-size: 16px;
  }
`

const LinkText = styled.p`
  &:after {
    background: ${colors.primary};
    content: '';
    display: block;
    height: 1px;
    transition: width .3s;
    width: 0;
  }

  &:hover::after{
    width: 100%;
  }
`
