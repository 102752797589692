import React, { useEffect } from 'react'
import styled from 'styled-components'
import { colors, mq } from '../../styles/theme'
import { motion } from 'framer-motion';

const Overlay = ({ show, onClose, children, hasFadeEffect, hasBackgroundOpacity, className }) => {

  useEffect(() => {
    document.body.addEventListener('keydown', closeOnEsc)
    return () => {
      document.body.removeEventListener('keydown', closeOnEsc)
    }
  })

  const closeOnEsc = e => {
    if ((e.charCode || e.keyCode) === 27){
      onClose()
    }
  }

  const commonAnimation = {
    x: {
      duration: 0.8
    },
    default: {
      ease: 'ease-in'
    }
  };

  const animateTransition = {
    delay: 0.1,
    ...commonAnimation
  };

  const variants = {
    show: {
      x: 0
    },
    hide: {
      x: 3000
    }
  }

  return (
    <ModalWrapper
      initial={'hide'}
      animate={ show ? 'show' : 'hide' }
      transition={animateTransition}
      variants={variants}
      className={className} has_background_opacity={hasBackgroundOpacity} onClick={onClose}>
      <ModalContent onClick={e => e.stopPropagation()} role='dialog' aria-modal='true'>
        <ModalBody>
          {children}
        </ModalBody>
        { hasFadeEffect && <ModalFade/> }
      </ModalContent>
    </ModalWrapper>
  )
}

export default Overlay

const ModalWrapper = styled(motion.div)`
  align-items: center;
  background-color: ${props => props.has_background_opacity && 'rgba(255, 255, 255, 0.8)'};
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10;

  ${mq.tablet} {
    padding-inline: 0;
  }
`

const ModalContent = styled.div`
  background-color: ${colors.white};
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);
  height: 100%;
  position: relative;
  width: 100%;
  padding-block-end: 20px;

  ${mq.tablet} {
    padding-block-end: 0;
  }
`

const ModalFade = styled.div`
  background-image: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255, 1) 90%);
  bottom: 0;
  height: 4em;
  left: 0;
  pointer-events: none;
  position: absolute;
  width: calc(100% - 20px);
  z-index: 1;
`

const ModalBody = styled.div`
  border-bottom: 1px solid ${colors.white};
  border-top: 1px solid ${colors.white};
  min-block-size: 150px;
  overflow-y: auto;
  height: 100%;
`
