import React, { useEffect, useRef, useState } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormController from './FormController'
import Button from '../Button'
import styled from 'styled-components'
import { colors, mq } from '../../styles/theme'
import { handleChangeUserInfo } from '../../services/profile'
import { useAlert } from '../../contextHooks/alerts'
import { actions } from '@storybook/addon-actions';

function FormikWrapper({ onClose }) {

  const initialValues = {
    oldPassword: '',
    newPassword: ''
  }

  const validationSchema = Yup.object({
    oldPassword: Yup.string().required('Please enter your old password.'),
    newPassword: Yup.string().required('Please enter your new password.')
  })

  const onSubmit = (values, actions) => {
    validationSchema
      .isValid(values)
      .then(async valid => {
        const body = JSON.stringify({
          pass: [{
            existing: values.oldPassword,
            value: values.newPassword
          }]
        })
        const res = await handleChangeUserInfo(body)
        if (res) {
          useAlert.setState({
            text: 'Password updated.',
            triggered: true,
            messageType: 'success'
          })
          onClose()
        } else {
          actions.setFieldError('oldPassword', 'The old password is incorrect')
        }
      })
  }
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {formik => (
        <Form>
          <FormFieldWrapper>
            <FormField>
              <FormController
                control="input"
                type="password"
                label="Old Password"
                name="oldPassword"
                onKeyUp={() => {
                  formik.setErrors({ ...formik.errors, 'oldPassword': null })
                  formik.setFieldTouched('oldPassword', false, false)
                }}
                error={formik.errors.oldPassword}
              />
            </FormField>
            <FormField>
              <FormController
                control="input"
                type="password"
                label="New Password"
                name="newPassword"
                onKeyUp={() => {
                  formik.setErrors({ ...formik.errors, 'newPassword': null })
                  formik.setFieldTouched('newPassword', false, false)
                }}
                error={ formik.errors.newPassword }
              />
            </FormField>
          </FormFieldWrapper>
          <FormButtonContainer>
            <FormButton isSubmit text="Update Password"/>

            <FormSigninLabel>
              <LinkPrimitive type="button" onClick={() => onClose()}>
                <LinkText>Cancel</LinkText>
              </LinkPrimitive>
            </FormSigninLabel>
          </FormButtonContainer>
        </Form>
      )}
    </Formik>
  )
}

export default FormikWrapper

const FormSigninLabel = styled.p`
  display: flex;
  justify-content: center;
`

const FormFieldWrapper = styled.div`
  column-gap: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${mq.tablet} {
    flex-direction: row;
  }
`

const FormField = styled.div`
  flex: 1;
`

const FormButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  margin-block-start: 15px;
  margin-block-end: 50px;
  row-gap: 20px;

  ${mq.tablet} {
    column-gap: 40px;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    row-gap: 0;
  }
`

const FormButton = styled(Button)`
  margin-block-start: 0;
  width: 100%;

  ${mq.tablet} {
    width: auto;
  }
`

const LinkPrimitive = styled.button`
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  color: ${colors.primary};
  font-size: 14px;
  line-height: 18px;
  margin-inline-start: 5px;
  text-decoration: none;

  ${mq.desktop} {
    font-size: 16px;
  }
`

const LinkText = styled.p`
  &:after {
    background: ${colors.primary};
    content: '';
    display: block;
    height: 1px;
    transition: width .3s;
    width: 0;
  }

  &:hover::after {
    width: 100%;
  }
`
