import React from 'react'
import styled from 'styled-components'
import { mq } from '../styles/theme'
import Layout from '../components/Layout/layout'
import Form from '../components/Form/LoginForm'
import OrbImage from '../components/OrbImage';

const Login = ({ pageContext }) => {
  const {
    PageTitle,
    PageSigninLink,
    PageSigninText
  } = pageContext

  return (
    <Layout>
      <FormBlock>
        <OrbElementTop>
          <OrbImage type={'stress-large'}/>
        </OrbElementTop>
        <OrbElementBottom>
          <OrbImage type={'health-large'}/>
        </OrbElementBottom>
        <FormContainer>
          <FormHeading>{PageTitle}</FormHeading>
          <Form signInLink={PageSigninLink} signInText={PageSigninText.processed}/>
        </FormContainer>
      </FormBlock>
    </Layout>
  )
}

export default Login

export { Head } from '../components/_GatsbyPageHead'

const FormBlock = styled.div`
  align-items: flex-start;
  display: flex;
  flex: 1;
  justify-content: center;
  min-block-size: 749px;
  position: relative;
  overflow: hidden;

  ${mq.desktop} {
    align-items: center;
  }
`

const FormContainer = styled.div`
  margin-block-start: 60px;
  margin-inline: 20px;
  max-inline-size: 400px;
  width: 100%;

  ${mq.tablet} {
    margin-inline: 200px;
  }

  ${mq.large} {
    margin-block-start: 0;
    margin-inline: 350px;
  }
`

const FormHeading = styled.h2`
  font-family: "BuckeyeSerif";
  font-weight: 700;
  font-size: 28px;
  margin-block-end: 23px;
  text-align: center;

  ${mq.desktop} {
    font-size: 48px;
  }
`

const OrbElementTop = styled.div`
  display: none;
  top: 0;
  left: 0;
  width: 500px;
  position: absolute;
  transform: translate(-50%,0%);

  ${mq.desktop} {
    display: block;
  }
`

const OrbElementBottom = styled.div`
  display: none;
  bottom: 0;
  right: 0;
  width: 600px;
  position: absolute;
  transform: translate(60%, 10%);

  ${mq.desktop} {
    display: block;
  }
`
