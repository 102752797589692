import { useStaticQuery, graphql } from 'gatsby'

export const useRoleTaxonomy = () => {
  const { allTaxonomyTermProfileRoles } = useStaticQuery(
    graphql`
      query roleTaxonomy {
        allTaxonomyTermProfileRoles {
            nodes {
              drupal_internal__tid
              name
              field_label
            }
        }
      }
    `
  )
  return allTaxonomyTermProfileRoles.nodes
}
