import React, { useEffect } from 'react'
import styled from 'styled-components'
import { colors, mq } from '../../styles/theme'
import closeIcon from '../../assets/close.svg'
import ContactInfoForm from '../Form/ContactForm'


// Use of modal: <Modal onClose={() => setShow(false)} show={show} title='Disclaimer'>content</Modal>

const ContactInfoModal = ({ show, onClose, title, hasFadeEffect, hasBackgroundOpacity, className, userContact }) => {
  useEffect(() => {
    document.body.addEventListener('keydown', closeOnEsc)
    return () => {
      document.body.removeEventListener('keydown', closeOnEsc)
    }
  })

  if (!show){
    return null
  }

  const closeOnEsc = e => {
    if ((e.charCode || e.keyCode) === 27){
      onClose()
    }
  }

  return (
    <ModalWrapper className={className} hasBackgroundOpacity={hasBackgroundOpacity} onClick={onClose}>
      <ModalContent onClick={e => e.stopPropagation()} role='dialog' aria-modal='true'>
        <ModalHeader>
          <CloseButton onClick={onClose}><CloseIcon src={closeIcon}/></CloseButton>
        </ModalHeader>
        <ModalBody>
          <ModalHeading>{title}</ModalHeading>
          <ContactInfoForm onClose={onClose} userContact={userContact}/>
        </ModalBody>
        { hasFadeEffect && <ModalFade/> }
      </ModalContent>
    </ModalWrapper>
  )
}

export default ContactInfoModal

const ModalWrapper = styled.div`
  align-items: center;
  background-color: ${props => props.hasBackgroundOpacity && 'rgba(255, 255, 255, 0.8)'};
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  padding-inline: 20px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10;

  ${mq.tablet} {
    padding-inline: 0;
  }
`

const ModalContent = styled.div`
  background-color: ${colors.white};
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);
  max-block-size: 740px;
  position: relative;
  width: 840px;
`

const ModalFade = styled.div`
  background-image: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255, 1) 90%);
  bottom: 0;
  height: 4em;
  left: 0;
  pointer-events: none;
  position: absolute;
  width: calc(100% - 20px);
  z-index: 1;
`

const ModalHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 15px;
  padding-block-end: 25px;
`

const CloseButton = styled.button`
  background: 0 0;
  border: 0;
  cursor: pointer;
  outline: 0;
`

const CloseIcon = styled.img``

const ModalBody = styled.div`
  border-bottom: 1px solid ${colors.white};
  border-top: 1px solid ${colors.white};
  min-block-size: 150px;
  max-block-size: 660px;
  overflow-y: auto;
  padding-block-end: 40px;
  padding-inline: 20px;

  ${mq.desktop} {
    padding-inline: 80px;
  }
`

const ModalHeading = styled.h2`
  font-family: "BuckeyeSans", sans-serif;
  font-weight: 700;
  font-size: 30px;
  line-height: 1;
  margin-block-end: 20px;

  ${mq.desktop} {
    font-size: 40px;
  }
`
