import React, { useState, useRef, useEffect } from 'react'
import { motion } from 'framer-motion';
import styled from 'styled-components'
import Overlay from './Overlay'
import { colors, mq } from '../../styles/theme'
import Rtf from '../Rtf/Article'
import DashboardOrbs from '../Orb/dashboard'
import { Canvas } from '@react-three/fiber'
import OrbBG from '../OrbImage';
import closeIcon from '../../assets/close.svg'
import Button from '../Button'
import Link from '../Link'
import useWindowSizeChange from '../../hooks/useWindowSizeChange'
import clickIcon from '../../assets/click.svg'
import tapIcon from '../../assets/tap.svg'
import { getMasthead } from '../../services/dashboard';
import { handleBackgroundColor, handleFontColor, handleThemeColor } from '../../utils/colorHandler';
import OrbImage from '../OrbImage';
import { drupalGetUser } from '../../services/profile';

const DashboardMasthead = ({ field_completed_quest_copy, field_not_completed_quest_copy, questionnaireStatus }) => {
  const orbRef = useRef([])
  const [show, setShow] = useState(false);
  const [dashboardMasthead, setDashboardMasthead] = useState(null);
  const [hoveredOrb, setHoveredOrb] = useState(-1);
  const [currentChapter, setCurrentChapter] = useState(null);
  const [username, setUsername] = useState('');
  const isMobile = useWindowSizeChange()

  useEffect(() => {
    drupalGetUser().then(data => {
      setUsername(data.personal_info?.field_first_name[0]?.value)
    })
  }, [])

  const handleClick = (e, i, chapter) => {
    setShow(true)
    setCurrentChapter(chapter)
  }

  const handleClose = () => {
    setShow(false)
  }

  const commonAnimation = {
    x: {
      duration: 0.5
    },
    opacity: {
      duration: 0.2
    },
    scale: {
      duration: 0.3
    },
    // eslint-disable-next-line quote-props
    default: {
      ease: 'linear'
    }
  };

  const animateTransition = {
    delay: 0,
    ...commonAnimation
  };

  const tooltipVariants = {
    hover: {
      opacity: 1
    },
    opaque: {
      opacity: 0

    }
  }

  const orbVariants = {
    hover: {
      opacity: 1,
      scale: 1.2
    },
    opaque: {
      opacity: 0.5,
      scale: 0.8
    }
  }

  const variants = {
    default: (position) => ({
      opacity: 1, x: position.x, y: position.y
    })
  }

  const positionsY = [150, 370, 170, 380, 300, 125]

  useEffect(() => {
    getMasthead().then(data => {
      let chapters = []
      if (data.chapters.length) {
        data.chapters.forEach((chapter, i) => {
          let sizeMultiplier = 1
          if (parseInt(chapter.threshold_position)) {
            if (parseInt(chapter.threshold_position) > 6) {
              sizeMultiplier = 6
            } else {
              sizeMultiplier = parseInt(chapter.threshold_position)
            }
          }

          let size, tooltipPositionY
          switch (sizeMultiplier) {
            case 1:
              size = 100
              tooltipPositionY = 90
              break;
            case 2:
              size = 150
              tooltipPositionY = 140
              break;
            case 3:
              size = 200
              tooltipPositionY = 180
              break;
            case 4:
              size = 250
              tooltipPositionY = 230
              break;
            case 5:
              size = 300
              tooltipPositionY = 270
              break;
            case 6:
              size = 400
              tooltipPositionY = 370
              break;
          }

          chapters.push({
            ...chapter,
            colors: handleThemeColor(chapter.chapter_theme || ''),
            tooltipPositionY,
            sizeMultiplier,
            positionX: '0px',
            positionY: `${positionsY[i]}px`,
            width: `${size}px`,
            height: `${size}px`,
            speed: 3 + Math.random()
          })
        })
      }

      data.chapters = chapters
      setDashboardMasthead(data)
    }).catch(e => {
      console.error('Error trying to get dashboard masthead', e.message)
    })
  }, []);

  return (
    <Section hasData={dashboardMasthead}>
      <WelcomeSection>
        <WelcomeHeading>
          {dashboardMasthead?.name ?
            <>
              Welcome<WelcomeUser>, {dashboardMasthead?.name}</WelcomeUser>
            </>
            :
            <>
              Welcome <WelcomeUser>{username}</WelcomeUser>
            </>
          }
        </WelcomeHeading>
        <WelcomeCopy>
          <Rtf copy={dashboardMasthead?.chapters.length > 0 ? field_completed_quest_copy?.value : field_not_completed_quest_copy?.value} />
        </WelcomeCopy>
      </WelcomeSection>
      <Overlay onClose={handleClose} show={show}>
        <OverlaySection>
          <LeftCol>
            <ModalHeaderMobile>
              <CloseButton onClick={handleClose}><CloseIcon src={closeIcon} /></CloseButton>
            </ModalHeaderMobile>
            <OrbTopContainer>
              <OrbHeading>{currentChapter?.['chapter_title']}</OrbHeading>
              <OrbBGWrapper>
                <OrbBG type={currentChapter?.['chapter_theme']} />
              </OrbBGWrapper>
            </OrbTopContainer>
            <OrbCopy>{currentChapter?.['threshold_description']}</OrbCopy>
            <OrbRow>
              {dashboardMasthead?.chapters.map((el, i) => (
                <OrbThumbnailWrapper key={i} onClick={(e) => handleClick(e, i, el)}>
                  <OrbBG type={el['chapter_theme']} />
                  <OrbThumnailTooltip className={'orb-thumbnail'}>{el['chapter_title']}</OrbThumnailTooltip>
                </OrbThumbnailWrapper>
              ))}
            </OrbRow>
          </LeftCol>
          <RightCol>
            <ModalHeader>
              <CloseButton onClick={handleClose}><CloseIcon src={closeIcon} /></CloseButton>
            </ModalHeader>
            <ChapterState chapterState={currentChapter?.['threshold_color']}>
              {currentChapter?.['threshold_label'] === 'No Data' ? 'Not Started' : currentChapter?.['threshold_label']}
            </ChapterState>
            <ChapterHeading>What does this mean</ChapterHeading>
            <ChapterCopy>
              <Rtf
                copy={currentChapter?.['threshold_additional_information'] === 'No data' ? 'No additional information' : currentChapter?.['threshold_additional_information']}
              />
            </ChapterCopy>
            <ChapterResourcesHeading>Recommended Resources</ChapterResourcesHeading>
            <ChapterCopy>
              <Rtf
                copy={'<p>Some resources will assist you reduce stress and improve well-being, whether you are feeling anxious or sad, for example. As a result, you may receive the same recommended resource to address different concerns. Try them. We recommend evidence-based resources proven to help you feel better. They will become a healthy habit to serve you well when practiced daily for 60-90 days.</p>'}
              />
            </ChapterCopy>
            <RecommendedResources>
              {!currentChapter?.resources.length && <span>No resources available</span>}
              {currentChapter?.resources.length > 0 && currentChapter?.resources.slice(0, 3).map((el, i) => {
                const link = el.type === 'internal_resource' ? `/resources${el.path}` : el.link
                const target = el.type === 'internal_resource' ? '_self' : '_blank'

                return (
                  <ResourceContainer key={i} href={link} target={target}>
                    <OrbImageWrapper>
                      <OrbBG type={el.taxonomies?.length ? el.taxonomies[0]?.theme : 'health'} />
                    </OrbImageWrapper>
                    {el.title} {el.type === 'external_resource' && <ExternalLinkIcon />}
                  </ResourceContainer>
                )
              })}
            </RecommendedResources>
            {currentChapter?.resources.length > 0 && <BtnSection>
              <ResourceBtn link={`/app/dashboard/resources/?chapter_id=${currentChapter?.chapter_id}`} text="View All Resources" />
              <Link href={`/questionnaire/${dashboardMasthead?.questionnaire_id}`} text="Retake Questionnaire" type="primary" />
            </BtnSection>}
          </RightCol>
        </OverlaySection>
      </Overlay>
      <CanvasSection>
        <OrbWrapper>
          {
            dashboardMasthead?.chapters.map((el, i) => (
              <OrbTooltipWrapper
                custom={{ x: el.positionX, y: el.positionY }}
                initial={'default'}
                animate={hoveredOrb === -1 ? 'default' : (hoveredOrb === i ? 'hover' : 'opaque')}
                transition={animateTransition}
                variants={variants}
                key={`${i}`}
                ref={el => orbRef.current.push(el)}
                width={el.width}
                height={el.height}
                onClick={(e) => handleClick(e, i, el)}
                onMouseEnter={() => setHoveredOrb(i)}
                onMouseLeave={() => setHoveredOrb(-1)}
              >
                <OrbContainer variants={orbVariants} transition={animateTransition}>
                  {/* <OrbCanvas>
                    This will be required once we tackle performance issues :)
                    <DashboardOrbs speed={el.speed} color1={el.colors.start} color2={el.colors.middle} color3={el.colors.end}/>
                  </OrbCanvas> */}
                  <OrbImage type={el.chapter_theme} />
                </OrbContainer>
                <OrbTooltip tooltip_position_y={el.tooltipPositionY} variants={tooltipVariants} transition={animateTransition}>
                  <OrbTooltipHeader>{el['chapter_title']}</OrbTooltipHeader>
                  <OrbTooltipCopy>
                    {el['threshold_description']}
                  </OrbTooltipCopy>
                </OrbTooltip>
              </OrbTooltipWrapper>
            ))
          }
        </OrbWrapper>
      </CanvasSection>
    </Section>
  )
}

export default DashboardMasthead

const Section = styled.section`
  align-items: center;
  block-size: ${(props) => props.hasData && "calc(100vh - 60px)"};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-inline: auto;
  max-width: 2000px;
  min-block-size: ${(props) => props.hasData && "780px"};
  position: relative;
  width: 100%;

  ${mq.desktop} {
    block-size: ${(props) => props.hasData && "calc(100vh - 100px)"};
  }
`;

const CanvasSection = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
`

const OrbWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(6, auto);
  justify-content: inherit;
  block-size: 100%;
  overflow-x: auto;
  margin-inline: auto;
  position: relative;

  ${mq.desktop} {
    justify-content: center;
  }
`

const LabelHelperWrapper = styled.div`
  margin-block-end: 54px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const LabelHelperIcon = styled.img`
  margin-block-end: 10px;
  width: 30px;

  ${mq.tablet} {
    width: 40px;
    margin-block-end: 12px;
  }
`

const LabelHelper = styled.span`
  font-size: 12px;
  color: ${colors.darkGray};
`

const OrbTooltip = styled(motion.div)`
  opacity: 0;
  position: absolute;
  transition: opacity 0.2s ease-in;
  min-inline-size: 200px;
  text-align: center;
  top: ${props => props.tooltip_position_y + 30}px;
`

const OrbTooltipHeader = styled.h5`
  font-family: "BuckeyeSans";
  font-weight: 700;
  font-size: 16px;
  color: ${colors.black};
`

const OrbTooltipCopy = styled.p`
  font-size: 13px;
  color: ${colors.darkGray};
`

const OrbTooltipWrapper = styled(motion.div)`
  position: relative;
  cursor: pointer;
  block-size: ${props => props.width} !important;
  inline-size: ${props => props.height} !important;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-right: -50px;

  ${mq.desktop} {
    margin-right: unset;
  }
`

const WelcomeHeading = styled.h1`
  margin-block-start: 50px;
`

const WelcomeSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const WelcomeUser = styled.span`
  background: linear-gradient(to left, ${colors.gradientGreen}, ${colors.primary});
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const WelcomeCopy = styled.div`
  max-inline-size: 620px;
  text-align: center;
`

const OrbCanvas = styled(Canvas)`
  cursor: pointer;
`

const OverlaySection = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;

  ${mq.desktop} {
    flex-direction: row;
  }
`

const LeftCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  flex: unset;
  padding-inline: 20px;

  ${mq.tablet} {
    flex: 50%;
    align-items: center;
    background: ${colors.solidGray};
    padding-inline: 0;
  }
`

const RightCol = styled.div`
  flex: unset;
  padding-inline: 20px;
  position: relative;

  ${mq.tablet} {
    flex: 50%;
    padding-inline: 60px;
  }
`

const OrbHeading = styled.h2`
  font-family: "BuckeyeSans";
  font-weight: 700;
  font-size: 30px;
  line-height: 1.05;
  margin-block-end: 10px;
  margin-inline: 0px;
  text-align: left;

  ${mq.tablet} {
    text-align: center;
    margin-inline: 80px;
    font-size: 40px;
    margin-block-end: 40px;
  }
`

const OrbThumbnailWrapper = styled.div`
  width: 50px;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  cursor: pointer;

  &:hover > span.orb-thumbnail {
    visibility: visible;
  }
`

const OrbThumnailTooltip = styled.span`
  position: absolute;
  top: 50px;
  font-family: "BuckeyeSans";
  font-weight: 700;
  font-size: 13px;
  visibility: hidden;
  height: 150px;
  width: 150px;
  text-align: center;
  overflow: hidden;
`

const OrbCopy = styled.p`
  max-inline-size: 400px;
  text-align: left;
  margin-block-end: 30px;

  ${mq.tablet} {
    text-align: center;
    margin-block-end: 120px;
  }
`

const OrbRow = styled.div`
  display: none;
  column-gap: 40px;

  ${mq.tablet} {
    display: flex;
  }
`

const OrbTopContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  align-items: start;

  ${mq.tablet} {
    flex-direction: column;
    width: initial;
    align-items: center;
  }
`

const ChapterState = styled.span`
  background-color: ${props => handleBackgroundColor(props.chapterState)};
  color: ${props => handleFontColor(props.chapterState)};
  display: inline-block;
  font-family: "BuckeyeSans";
  font-size: 12px;
  margin-block-end: 10px;
  padding-block: 1px;
  padding-inline: 10px;
  min-inline-size: 80px;
  text-align: center;

  ${mq.tablet} {
    min-inline-size: 102px;
    padding-block: 4px;
    padding-inline: 11px;
  }
`;

const ChapterHeading = styled.h3`
  font-family: "BuckeyeSans";
  font-weight: 600;
  font-size: 21px;
  line-height: 1.29;
  margin-block-end: 10px;

  ${mq.tablet} {
    font-size: 28px;
    margin-block-end: 12px;
  }
`

const ChapterCopy = styled.div`
  margin-block-end: 30px;

  ${mq.tablet} {
    margin-block-end: 34px;
  }
`

const ChapterResourcesHeading = styled.h3`
  font-family: "BuckeyeSans";
  font-weight: 600;
  font-size: 21px;
  line-height: 1.29;
  margin-block-end: 10px;

  ${mq.tablet} {
    font-size: 28px;
    margin-block-end: 20px;
  }
`

const RecommendedResources = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-block-end: 20px;
`

const OrbImageWrapper = styled.div`
  width: 50px;
  bottom: 0;
  position: absolute;
  right: 0;
  transform: translate(35%, 45%);
  z-index: 1;
  transition: all 0.4s ease-in-out;

  ${mq.tablet} {
    width: 100px;
  }
`

const ResourceContainer = styled.a`
  min-block-size: 50px;
  padding-block: 10px;
  background: ${colors.solidGray};
  font-family: "BuckeyeSans";
  font-weight: 600;
  font-size: 16px;
  line-height: 1.21;
  display: flex;
  align-items: center;
  padding-inline-start: 20px;
  position: relative;
  overflow: hidden;
  color: ${colors.black};
  text-decoration: none;

  &:hover {
    ${OrbImageWrapper} {
      transform: translate(35%, 45%) scale(1.2) rotateZ(15deg);
    }

    color: ${colors.primary};
  }

  ${mq.tablet} {
    padding-inline-start: 40px;
    min-block-size: 90px;
    font-size: 19px;
  }
`

const OrbBGWrapper = styled.div`
  width: 50px;

  ${mq.tablet} {
    width: 300px;
  }
`

const ModalHeader = styled.div`
  display: none;
  justify-content: flex-end;
  padding: 15px;

  ${mq.tablet} {
    display: flex;
  }
`

const ModalHeaderMobile = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-block: 15px;
  width: 100%;

  ${mq.tablet} {
    display: none;
  }
`

const CloseButton = styled.button`
  background: 0 0;
  border: 0;
  cursor: pointer;
  outline: 0;
`

const CloseIcon = styled.img``

const BtnSection = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;

  ${mq.tablet} {
    flex-direction: row;
  }
`

const ResourceBtn = styled(Button)`
  margin-block-start: 0;
  margin-block-end: 20px;
  width: 100%;

  ${mq.tablet} {
    width: initial;
    margin-block-end: 0;
  }
`

const OrbContainer = styled(motion.div)`
  width: 100%;
  height: 100%;
`
const ExternalLinkIcon = styled.span`
`
