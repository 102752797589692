import React, { useState, useRef, useEffect } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormController from './FormController'
import Button from '../Button'
import styled from 'styled-components'
import { colors, mq } from '../../styles/theme'
import { useGenderTaxonomy } from '../../utils/queries/genderTaxonomy'
import { useRoleTaxonomy } from '../../utils/queries/profileRoles'
import { handlePersonalInfoChange } from '../../services/profile'
import { useAlert } from '../../contextHooks/alerts'
import moment from 'moment';
import { usePersonalInfo } from '../../contextHooks/personalInfo'

function FormikWrapper({ onClose, userProfile }) {

  const roleRef = useRef(null)

  const [childField, setChildField] = useState([])

  const [childSelectorLabel, setChildSelectorLabel] = useState('')

  const genderChoices = []

  const roleChoice = []

  const roleChildChoices = []

  const roleChoices = useRoleTaxonomy();

  const personalInfo = usePersonalInfo(state => state)

  useGenderTaxonomy().map(el => (
    genderChoices.push({
      key: el.name, value: el.drupal_internal__tid
    })
  ))

  const dateHandler = date => {
    if (!date) return
    const dateStr = moment(date)
    return dateStr.format('MM/DD/YYYY')
  }

  const filterData = (list, field) => {
    const obj = list.find(el => el.drupal_internal__tid === userProfile[field][0]?.target_id)
    return obj
  }

  const rolesWithNoParent = roleChoices.filter(el => el.relationships?.parent.length === 0 || [])

  rolesWithNoParent.map(el => (
    roleChoice.push({
      key: el.name, value: el.drupal_internal__tid
    })
  ))

  useEffect(() => {
    changeDynamicChild(personalInfo.roleParentId ? personalInfo.roleParentId : roleRef.current.querySelector('select').value)
  }, [personalInfo])

  const initialValues = {
    name: personalInfo.field_first_name[0]?.value || '',
    lastName: personalInfo.field_last_name[0]?.value || '',
    role: personalInfo?.roleParentId || personalInfo.field_role[0]?.target_id,
    secondaryRole: personalInfo.field_role[0]?.target_id || 0,
    date: moment(personalInfo.field_date_of_birth[0]?.value, 'YYYY-MM-DD').format('MM-DD-YYYY'),
    gender: personalInfo.field_gender[0]?.target_id || null
  }

  let parentRole
  useEffect(() => {
    if (userProfile['field_role'][0]?.target_id) {
      parentRole = filterData(roleChoices, 'field_role')?.relationships?.parent[0]?.drupal_internal__tid
    }
  }, [userProfile]);

  setTimeout(() => {
    const event = new Event('change', { bubbles: true });
    const roleField = document.querySelector('#role')
    if (roleField) {
      roleField.dispatchEvent(event);
    }
  }, 100)

  const validationSchema = Yup.object({
    name: Yup.string().required('Please enter your name.'),
    lastName: Yup.string().optional(),
    role: Yup.string().optional(),
    secondaryRole: Yup.string().optional(),
    date: Yup.date().optional().min(moment().subtract(100, 'years'), 'Please enter a valid date').max(moment(), 'Please enter a valid date').typeError('Please enter a valid date'),
    gender: Yup.string().optional()
  })

  const changeDynamicChild = e => {
    const selectedValue = parseInt(e?.target?.value || e);
    const children = roleChoices.filter(el => el.relationships?.parent[0]?.drupal_internal__tid === selectedValue || [])
    children.map(el => (
      roleChildChoices.push({
        key: el.name, value: el.drupal_internal__tid
      })
    ))
    const parentSelector = roleChoices.find(el => el.drupal_internal__tid === selectedValue)?.field_label
    setChildSelectorLabel(parentSelector)
    setChildField(roleChildChoices)
  }

  const dateFormat = date => {
    if (!date) return
    const dateStr = new Date(date)
    return `${dateStr.getFullYear()}-${dateStr.getMonth() + 1}-${dateStr.getDate()}`
  }

  const onSubmit = values => {
    validationSchema
      .isValid(values)
      .then(async valid => {
        const body = JSON.stringify({
          field_first_name: [{
            value: values.name
          }],
          field_last_name: [{
            value: values.lastName
          }],
          field_date_of_birth: [{
            value: dateFormat(values.date)
          }],
          field_role: [{
            target_id: values.role
          }],
          field_gender: [{
            target_id: parseInt(values.gender)
          }],
          bundle: 'personal_info'
        })
        const res = await handlePersonalInfoChange(body)
        if (res){
          useAlert.setState({
            text: "You've successfully updated your personal info.",
            triggered: true,
            messageType: 'success'
          })
          onClose()
        }
      })
  }
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      enableReinitialize
    >
      {formik => (
        <Form>
          <FormFieldWrapper>
            <FormField>
              <FormController
                control="input"
                type="text"
                label="First Name"
                name="name"
                error={ formik.errors.name }
              />
            </FormField>
            <FormField>
              <FormController
                control="input"
                type="text"
                label="Last Name"
                name="lastName"
              />
            </FormField>
            <FormField className={'role-field'} ref={roleRef} onChange={e => changeDynamicChild(e)}>
              <FormController
                control="select"
                label="Role"
                name="role"
                options={roleChoice}
              />
            </FormField>
            <FormField>
              <FormController
                control="input"
                type="date"
                label="Date of Birth"
                name="date"
              />
            </FormField>
            <FormField>
              <FormController
                control="select"
                label="Gender"
                name="gender"
                options={genderChoices}
              />
            </FormField>
          </FormFieldWrapper>
          <FormButtonContainer>
            <FormButton isSubmit text="Save Changes" />

            <FormCancelLabel>
              <LinkPrimitive type='button' onClick={() => onClose()}>
                <LinkText>Cancel</LinkText>
              </LinkPrimitive>
            </FormCancelLabel>
          </FormButtonContainer>
        </Form>
      )}
    </Formik>
  )
}
export default FormikWrapper

const FormCancelLabel = styled.div`
  display: flex;
  justify-content: center;
`

const FormFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;

  ${mq.tablet} {
    flex-direction: row;
  }
`

const FormField = styled.div`
  flex-basis: 48%;
`

const LinkPrimitive = styled.button`
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  color: ${colors.primary};
  font-size: 14px;
  line-height: 18px;
  margin-inline-start: 5px;
  text-decoration: none;

  ${mq.desktop} {
    font-size: 16px;
  }
`

const LinkText = styled.p`
  &:after {
    background: ${colors.primary};
    content: '';
    display: block;
    height: 1px;
    transition: width .3s;
    width: 0;
  }

  &:hover::after{
    width: 100%;
  }
`

const FormButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  margin-block-start: 15px;
  margin-block-end: 50px;
  row-gap: 20px;

  ${mq.tablet} {
    column-gap: 40px;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    row-gap: 0;
  }
`

const FormButton = styled(Button)`
  margin-block-start: 0;
  width: 100%;

  ${mq.tablet} {
    width: auto;
  }
`
