import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import { drupalGetUser } from '../services/profile';
import { useGenderTaxonomy } from '../utils/queries/genderTaxonomy';
import { useRoleTaxonomy } from '../utils/queries/profileRoles';
import { colors, mq } from '../styles/theme';
import Layout from '../components/Layout/layout'
import OrbImage from '../components/OrbImage';
import PersonalInfoModal from '../components/Modal/ModalPersonalInfo';
import ContactInfoModal from '../components/Modal/ContactInfo';
import UpdatePasswordModal from '../components/Modal/UpdatePassword';
import DeactivateAccountModal from '../components/Modal/ModalDeactivateAccount';
import AlertMessageWContext from '../components/ServerMessage/alertMessageWContext';
import moment from 'moment';
import { useAlert } from '../contextHooks/alerts';
import { usePersonalInfo } from '../contextHooks/personalInfo';
import { isAuthSSO } from '../services/auth';

const ProfilePage = ({ pageContext }) => {
  const { PageTitle } = pageContext
  const [showPersonalModal, setShowPersonalModal] = useState(false)
  const [showContactModal, setShowContactModal] = useState(false)
  const [showPasswordModal, setShowPasswordModal] = useState(false)
  const [showAccountModal, setShowAccountModal] = useState(false)
  const genders = useGenderTaxonomy()
  const roles = useRoleTaxonomy()
  const [userProfile, setUserProfile] = useState({
    field_first_name: [
      {
        value: ''
      }
    ],
    field_last_name: [
      {
        value: ''
      }
    ],
    field_gender: [
      {
        value: ''
      }
    ],
    field_date_of_birth: [
      {
        value: ''
      }
    ],
    field_role: [
      {
        value: ''
      }
    ]
  })

  const [userContact, setUserContact] = useState({
    field_phone_number: [
      {
        value: ''
      }
    ],
    mail: [
      {
        value: ''
      }
    ]
  })

  useEffect(() => {
    drupalGetUser()
      .then(user => {
        setUserProfile(user.personal_info)
        usePersonalInfo.setState({ ...user.personal_info, roleParentId })
        setUserContact(user.user)
      })
  }, [showContactModal, showPersonalModal])

  const filterData = (list, field) => {
    const obj = list.find(el => el.drupal_internal__tid === userProfile[field][0]?.target_id)
    return obj
  }

  const dateHandler = date => {
    if (!date) return
    const dateStr = moment(date)
    return dateStr.format('MM/DD/YYYY')
  }

  let roleParent
  useEffect(() => {
    if (userProfile['field_role'][0]?.target_id) {
      roleParent = filterData(roles, 'field_role')?.relationships?.parent[0]?.name
    }
  }, [userProfile]);

  const roleParentId = filterData(roles, 'field_role')?.relationships?.parent[0]?.drupal_internal__tid

  const hideAlert = () => {
    setTimeout(() => {
      useAlert.setState({
        text: null,
        triggered: false,
        messageType: null
      })
    }, 5000)
  }

  const formatPhone = (phone) => {
    const match = phone.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `${match[1]}-${match[2]}-${match[3]}`;
    }
    return phone
  }

  return (
    <Layout>
      <Section>
        <PersonalInfoModal userProfile={userProfile} hasBackgroundOpacity onClose={() => {setShowPersonalModal(false); hideAlert()}} show={showPersonalModal} title='Personal Info' />
        <ContactInfoModal userContact={userContact} hasBackgroundOpacity onClose={() => {setShowContactModal(false); hideAlert()}} show={showContactModal} title='Contact Info' />
        <UpdatePasswordModal hasBackgroundOpacity onClose={() => {setShowPasswordModal(false); hideAlert()}} show={showPasswordModal} title='Change Password' />
        <DeactivateAccountModal hasBackgroundOpacity onClose={() => {setShowAccountModal(false); hideAlert()}} show={showAccountModal} title='Are you sure you want to deactivate your account?' />

        <ProfileContent>
          <OrbElementTop>
            <OrbImage type={'nocolor-large'}/>
          </OrbElementTop>
          <Heading>{PageTitle}</Heading>
          <AlertMessageWContext />
          <InfoContainer>
            <PersonalInfoSection>
              <PersonalInfoHeading>Personal Info</PersonalInfoHeading>
              <PersonalInfoCopy>{userProfile.field_first_name[0]?.value} {userProfile.field_last_name[0]?.value}</PersonalInfoCopy>
              {
                roleParent && <PersonalInfoCopy>{roleParent}</PersonalInfoCopy>
              }
              <PersonalInfoCopy>{filterData(roles, 'field_role')?.name}</PersonalInfoCopy>
              <PersonalInfoCopy>{dateHandler(userProfile.field_date_of_birth[0]?.value)}</PersonalInfoCopy>
              <BtnContainer>
                <PersonalInfoCopy>{filterData(genders, 'field_gender')?.name}</PersonalInfoCopy>
                <LinkPrimitive type='button' onClick={() => setShowPersonalModal(true)}>
                  <LinkText>Edit</LinkText>
                </LinkPrimitive>
              </BtnContainer>

              <Divider />

              <PersonalInfoHeading>Contact Info</PersonalInfoHeading>
              <PersonalInfoCopy>{userContact.field_phone_number[0]?.value ? formatPhone(userContact.field_phone_number[0]?.value) : ''}</PersonalInfoCopy>
              <BtnContainer>
                <PersonalInfoCopy>{userContact.mail[0]?.value}</PersonalInfoCopy>
                <LinkPrimitive type='button' onClick={() => setShowContactModal(true)}>
                  <LinkText>Edit</LinkText>
                </LinkPrimitive>
              </BtnContainer>
            </PersonalInfoSection>
            <RightColumnContainer>
              {
                !isAuthSSO() &&
                  <ChangePasswordSection>
                    <PersonalInfoHeading>Change Password</PersonalInfoHeading>
                    <BtnContainer>
                      <PersonalInfoCopy>**********</PersonalInfoCopy>
                      <LinkPrimitive type='button' onClick={() => setShowPasswordModal(true)}>
                        <LinkText>Edit</LinkText>
                      </LinkPrimitive>
                    </BtnContainer>
                  </ChangePasswordSection>
              }
              <DeactivateAccount>
                <PersonalInfoHeading>Deactivate Account</PersonalInfoHeading>
                <PersonalInfoCopy>
                  By deactivating your account, you will no longer be able to access your account.
                </PersonalInfoCopy>
                <DeactivateButton type='button' onClick={() => setShowAccountModal(true)}>
                  <DeactivateBtnText>Deactivate Account</DeactivateBtnText>
                </DeactivateButton>
              </DeactivateAccount>
            </RightColumnContainer>
          </InfoContainer>
          <OrbElementBottom>
            <OrbImage type={'nocolor-large'}/>
          </OrbElementBottom>
        </ProfileContent>
      </Section>
    </Layout>
  )
}

export default ProfilePage

export { Head } from '../components/_GatsbyPageHead'

const Section = styled.div`
  min-block-size: 749px;
  position: relative;
  overflow: hidden;
  margin-inline: 20px;
  margin-block-end: 60px;

  ${mq.tablet} {
    margin-inline: initial;
    margin-block-end: 0;
  }
`

const ProfileContent = styled.div`
  margin: auto;
  max-inline-size: 840px;
  margin-block-end: 0;

  ${mq.tablet} {
    margin-block-end: 60px;
  }
`

const Heading = styled.h1`
  margin-block-start: 60px;
  margin-block-end: 20px;
  text-align: center;

  ${mq.tablet} {
    margin-block-start: 100px;
    margin-block-end: 50px;
    text-align: left;
  }
`

const InfoContainer = styled.div`
  display: flex;
  row-gap: 30px;
  align-items: flex-start;
  flex-wrap: wrap;

  ${mq.tablet} {
    flex-wrap: nowrap;
    column-gap: 40px;
    row-gap: 0;
  }
`

const RightColumnContainer = styled.div`
  min-inline-size: 290px;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  width: 100%;

  ${mq.tablet} {
    width: initial;
  }
`

const PersonalInfoSection = styled.div`
  background: ${colors.white};
  max-inline-size: none;
  min-block-size: 371px;
  padding: 40px 30px;
  width: 100%;

  ${mq.tablet} {
    max-inline-size: 510px;
    min-block-size: 399px;
    padding: 50px 20px;
  }
`

const ChangePasswordSection = styled.div`
  background: ${colors.white};
  max-inline-size: none;
  min-block-size: 130px;
  padding: 40px 30px;

  ${mq.tablet} {
    max-inline-size: 290px;
    min-block-size: 167px;
    padding: 50px 20px;
  }
`

const DeactivateAccount = styled.div`
  background: ${colors.white};
  max-inline-size: none;
  min-block-size: 218px;
  padding: 40px 30px;

  ${mq.tablet} {
    max-inline-size: 290px;
    min-block-size: 287px;
    padding: 50px 20px;
  }
`

const PersonalInfoHeading = styled.h4`
  font-family: "BuckeyeSans";
  font-weight: 600;
  color: ${colors.black};
  line-height: 1.21;
  font-size: 16px;
  margin-block-end: 12px;

  ${mq.desktop} {
    font-size: 19px;
  }
`

const PersonalInfoCopy = styled.p`
  color: ${colors.black};
`

const BtnContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const LinkPrimitive = styled.button`
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  color: ${colors.primary};
  font-size: 14px;
  line-height: 18px;
  text-decoration: none;

  ${mq.desktop} {
    font-size: 16px;
  }
`

const LinkText = styled.p`
  &:after {
    background: ${colors.primary};
    content: '';
    display: block;
    height: 1px;
    transition: width .3s;
    width: 0;
  }

  &:hover::after{
    width: 100%;
  }
`

const Divider = styled.hr`
  border-top: 1px solid ${colors.midGray};
  margin: 30px 0;
`

const DeactivateButton = styled.button`
  background: transparent;
  border: 2px solid ${colors.primary};
  color: ${colors.primary};
  cursor: pointer;
  display: inline-block;
  margin-block-start: 30px;
  padding: 13px 18px;
  text-decoration: none;
  position: relative;
  -webkit-backface-visibility: hidden;
  width: 100%;
  z-index: 1;

  &::after{
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to right, ${colors.gradientGreen}, ${colors.primary});
    transition: opacity 0.3s ease-out;
    z-index: 2;
    opacity: 0;
  }

  &:hover::after{
    opacity: 1;
  }

  &:hover {
    border: none;
    color: ${colors.white};
    padding: 15px 18px;
  }

  ${mq.desktop} {
    max-inline-size: 180px;
    text-align: center;
  }
`

const DeactivateBtnText = styled.span`
  position: relative;
  z-index: 3;
`

const OrbElementTop = styled.div`
  display: none;
  top: 0;
  left: 0;
  width: 500px;
  position: absolute;
  transform: translate(-50%,0%);
  z-index: -1;

  ${mq.desktop} {
    display: block;
  }
`

const OrbElementBottom = styled.div`
  display: none;
  bottom: 0;
  right: 0;
  width: 600px;
  position: absolute;
  transform: translate(60%, 10%);
  z-index: -1;

  ${mq.desktop} {
    display: block;
  }
`
