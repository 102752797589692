import React from 'react'
import styled from 'styled-components'
import { colors, mq } from '../../styles/theme'

const QuoteRow = ({ copy, image, name, position }) => {

  return (
    <QuoteRowSection>
      {
        image &&
        <ImageWrapper>
          <QuoteImg src={image} width={140} height={140} alt='' />
        </ImageWrapper>
      }
      <Quote>
        {copy}
      </Quote>
      {name && <QuoteName>{name}</QuoteName>}
      {position && <QuotePosition>{position}</QuotePosition>}
    </QuoteRowSection>
  )
}

export default QuoteRow

const QuoteRowSection = styled.section`
  align-items: center;
  background: linear-gradient(to left, #4ba492, ${colors.primary});
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-block-size: 534px;
  padding-block: 40px;
  padding-inline: 20px;
  margin-bottom: 40px;
  width: 100%;

  ${mq.tablet} {
    padding-block: 80px;
    padding-inline: 220px;
    margin-bottom: 60px;
  }
`;

const ImageWrapper = styled.div`
  margin-block-end: 20px;
`

const ImageInnerContainer = {
  border: `2px solid ${colors.white}`,
  borderRadius: '100%'
}

const Image = {
  borderRadius: '100%',
  padding: '3px'
}

const Quote = styled.p`
  color: ${colors.white};
  font-family: "BuckeyeSans";
  font-weight: 600;
  font-size: 25px;
  line-height: 1.36;
  margin-block-end: 20px;
  text-align: center;

  ${mq.tablet} {
    font-size: 33px;
  }
`

const QuoteName = styled.h5`
  color: ${colors.white};
  font-family: "BuckeyeSans";
  font-weight: 600;
  font-size: 16px;
  line-height: 1.21;
  margin-block-end: 12px;

  ${mq.tablet} {
    font-size: 19px;
  }
`

const QuotePosition = styled.p`
  color: ${colors.white};
`

const QuoteImg = styled.img`
  border: 2px solid ${colors.white};
  border-radius: 100%;
  object-fit: cover;
`
