import { getToken, isLoggedIn } from './auth';

const requestMastheadUrl = `${process.env.DRUPAL_JSONAPI_URL}dashboard/masthead?_format=json`
const requestFavoriteResourcesUrl = `${process.env.DRUPAL_JSONAPI_URL}dashboard/favorite-resources/?_format=json`

export const getMasthead = async () => {
  const token = getToken()

  if (token && isLoggedIn()) {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }

    const response = await fetch(requestMastheadUrl, {
      method: 'GET',
      headers
    })

    const data = await response.json();

    return data
  } else {
    return {}
  }
}

export const getFavoriteResources = async (page) => {
  const token = getToken()

  if (token && isLoggedIn()) {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }

    const response = await fetch(requestFavoriteResourcesUrl, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        page,
        items_per_page: 6
      })
    })

    const data = await response.json();

    return data
  } else {
    return {}
  }
}
