import { useStaticQuery, graphql } from 'gatsby'

export const useGenderTaxonomy = () => {
  const { allTaxonomyTermProfileGender } = useStaticQuery(
    graphql`
      query genderTaxonomy {
        allTaxonomyTermProfileGender {
          nodes {
            name
            drupal_internal__tid
          }
        }
      }
    `
  )
  return allTaxonomyTermProfileGender.nodes
}
